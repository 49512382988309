import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/blog-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Week 1`}</h1>
    <p>{`First week of my listening journal, woo! This week contains some music of the stuff I’ve had on repeat while working on this website. You’ll find some of the best (deep) house, emotional sounds from Leafar Legov and even some mind-blowing Jungle! Dig in :).`}<div style={{
        marginBottom: "80px"
      }} /></p>
    <iframe width="180" height="180" src="https://www.youtube.com/embed/5q_dOOMHeaw" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Ali Nasser - The Way Out`}</h1>
    <p>{`I found this new artist while digging through the amazing storytellers YouTube channel. Fine minmal house which you can’t listen only once!`}</p>
    <p>{`Support the artist and label:`}</p>
    <div style={{
      lineHeight: '0px'
    }}>
      <p><a parentName="p" {...{
          "href": "https://www.deejay.de/Ali_Nasser_The_Way_Out_EP_PLZ000_Vinyl__930333"
        }}>{`Deejay.de`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.decks.de/track/ali_nasser-the_way_out_ep/cem-5w"
        }}>{`Decks.de`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.yoyaku.io/release/plz000/?fbclid=IwAR1oGobCALoTzOYVlVm7ttIcY6vt2WM4hV4vQW7pkJ7HhbOF6LIL4F6oLTc"
        }}>{`Yoyaku`}</a></p>
      <p><a parentName="p" {...{
          "href": "https://www.juno.co.uk/products/ali-nasser-the-way-out-ep/728290-01/"
        }}>{`Juno`}</a></p>
    </div>
    <div style={{
      marginBottom: "80px"
    }} />
    <iframe width="180" height="180" src="https://www.youtube.com/embed/6PZGDS1fR8M" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Leafar Legov - Alone`}</h1>
    <p>{`One of the tracks I’ve had on my repeat during my holiday break. Leafar Legov does it once again with some 2020 disco. Alone, Alone, Alone…`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.discogs.com/Various-2019/release/14545885"
      }}>{`Sold out -> Discogs sharks`}</a></p>
    <div style={{
      marginBottom: "80px"
    }} />
    <iframe width="180" height="180" src="https://www.youtube.com/embed/IH9p8D8bcMo" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`Ron Tom - Give Me da Weed`}</h1>
    <p>{`Some fine old school jungle from 1995, a hidden gem!`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.discogs.com/Ron-Tom-Give-Me-Da-Weed-Pirates/release/230846"
      }}>{`Sold out -> Discogs sharks`}</a></p>
    <div style={{
      marginBottom: "80px"
    }} />
    <iframe width="180" height="180" src="https://www.youtube.com/embed/z78Pc_m74ys" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h1>{`dot13 - Mineral Jam`}</h1>
    <p>{`Dot13 is a young Romanian artist currently living in Vienna, Austria. He’s trying to pursue his dream of becoming a music producer. His sound is set in a modern post-minimal scenario with a funky-groove twist. This track will take you on a 11 minute trip!`}</p>
    <p>{`Support the artist and label:`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.decks.de/track/dot13-dumitru_prunariu/cfv-f7"
      }}>{`Decks.de`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.juno.co.uk/products/dot13-dumitru-prunariu/757609-01/?fbclid=IwAR3zTBvF2buQKd6wGUtfaTOHJKGW_xauwhb8TIB4Row9AA0xPvI7uK9-iHU"
      }}>{`Juno`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.discogs.com/dot13-Dumitru-Prunariu/release/14496382?fbclid=IwAR1pN48bjFATmFCcSIjbpTLtQRk3DDDrv7fc_zK8SPYCTbjJphWzqXYv1K8"
      }}>{`Discogs`}</a></p>
    <div style={{
      marginBottom: "80px"
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      